import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './NotFound.module.sass';

export default function NotFoundPage(): ReactElement {
  return (
    <div className={styles.container}>
      <h1>
        <FormattedMessage
          id={'notfound.title'}
          defaultMessage={
            'Es tut uns Leid, die angeforderte Seite konnte nicht gefunden werden.'
          }
          description={'This is the Title of the Not Found / Error 404 Page'}
        />
      </h1>
      <h2>
        <FormattedMessage
          id={'notfound.subtitle'}
          defaultMessage={
            'Bitte überprüfen Sie die URL und versuchen Sie es erneut.'
          }
          description={'This is the Subtitle of the Not Found / Error 404 Page'}
        />
      </h2>
      <h3>
        <FormattedMessage
          id={'notfound.homepage'}
          defaultMessage={'Zurück zur '}
          description={'This is the Subtitle of the Not Found / Error 404 Page'}
        />
        <a href={'/'}>
          <FormattedMessage
            id={'notfound.homepage.link'}
            defaultMessage={'Startseite'}
            description={
              'This is the Subtitle of the Not Found / Error 404 Page'
            }
          />
        </a>
      </h3>
    </div>
  );
}
