import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.sass';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import consola from 'consola';
import NotFoundPage from './pages/NotFound';
import HomePage from './pages/HomePage';

function loadLocaleData(locale: string) {
  switch (locale) {
    case 'en-US':
      return import('./i18n/compiled/en-US.json');
    default:
      return import('./i18n/compiled/de-DE.json');
  }
}

interface AppProps {
  locale: string;
  messages: any;
}

function App(props: AppProps) {
  return (
    <React.StrictMode>
      <IntlProvider
        messages={props.messages}
        locale={props.locale}
        defaultLocale='en'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path={'*'} element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </React.StrictMode>
  );
}

let root: ReactDOM.Root | null = null;

const browserLanguage = navigator.language;
if (browserLanguage) {
  switch (browserLanguage) {
    case 'en-US':
    case 'de-DE':
      bootstrapApplication(browserLanguage);
      break;
    default:
      consola.error(
        'Unexpected Language:',
        browserLanguage,
        "Falling back to 'de-DE'",
      );
      consola.info(
        'If you want to support this language, please write an issue via mail to sebastian@schindlertai.de and use the following subject: "[SHT] Lang-Support ' +
          browserLanguage +
          '"',
      );
      bootstrapApplication('de-DE');
      break;
  }
}

async function bootstrapApplication(locale: string) {
  const messages = await loadLocaleData(locale);
  const container = document.getElementById('root') as HTMLElement;
  if (!root) {
    root = ReactDOM.createRoot(container);
  }

  root.render(<App locale={locale} messages={messages} />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(consola.debug);
