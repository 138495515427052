import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './HomePage.module.sass';

export default function HomePage(): ReactElement {
  return (
    <div className={styles.container}>
      <h1>
        <FormattedMessage
          id={'homepage.title'}
          defaultMessage={'Willkommen bei dem Smart Habit Tracker!'}
          description={'Title of the Homepage'}
        />
      </h1>
      <h2>
        <FormattedMessage
          id={'homepage.subtitle'}
          defaultMessage={
            'Hier kannst du deine Gewohnheiten verfolgen und verbessern.'
          }
          description={'Subtitle of the Homepage'}
        />
      </h2>
    </div>
  );
}
